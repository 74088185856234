import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import questions from './AssessmentReducer';
import responses from './ResponseReducer';
import avatar from './AvatarReducer';
import reports from './ReportReducer';
import carePlan from './CarePlanReducer';
import reportById from './ReportByIdReducer';
import reportProcess from './ReportProcessReducer';
import reportList from './ReportListReducer';
import goal from './GoalReducer';
import loginRedirect from './LoginRedirectReducer';
import profile from './ProfileReducer';
import modal from './ModalReducer';
import chatWindow from './ChatWindowReducer';
import chat from './ChatReducer';
import conversationalAssessment from './ConversationalAssessmentReducer';
import chatResponses from './ChatAssessmentResponseReducer';
import chatReport from './ChatReportReducer';
import isAnonymousAlertVisible from './dashboard/AnonymousAlertReducer';
// eslint-disable-next-line import/no-cycle
import requestCoach from './dashboard/CoachRequestReducer';
import { currentUser, infoTab } from './coachchat/ActiveUserReducer';
import pushNotificationData from './push-notification/PushDataReducer';
// eslint-disable-next-line import/no-cycle
import dashBoard from './dashboard/DashBoardReducer';
import videoTherapy from './dashboard/VideoTherapyRequestReducer';
import inPersonTherapy from './dashboard/InPersonTherapyRequestReducer';
// eslint-disable-next-line import/no-cycle
import companyList from './CompanyListReducer';
import companyDetails from './CompanyDetailsReducer';
import registrationFields from './RegistrationFieldsReducer';
import messageList from './coachchat/MessageListReducer';
import sessionExpired from './SessionExpiredReducer';
import mediaList from './coachchat/MediaListReducer';
import videoPlayerModal from './media/VideoPlayerModalReducer';
import workLifeRequest from './dashboard/WorkLifeRequestReducer';
import keyboard from './KeyboardReducer';
import videoMode from './VideoFullScreenReducer';
import settings from './SettingsReducer';
import calendarEvents from './CalendarEventsReducer';
import recentActivity from './RecentActivityReducer';
import chatState from './ChatStateReducer';
import subtitles from './SubtitlesReducer';
import reportReadMore from './ReportAccordianReducer';
import conversation from './ConversationReducer';
import feedbackSurvey from './FeedbackSurveyReducer';
// eslint-disable-next-line import/no-cycle
import appSync from './AppSyncReducer';
import idTransfer from './IdTransferReducer';
import skipToMainContent from './SkipToMainContentReducer';
import locationRedirection from './LocationReducer';

const coachChat = combineReducers({ messageList, mediaList });
const media = combineReducers({ videoPlayerModal });

// If you want to persist some state across login then make sure to preserve it in
// root reducer declaration in redux store file

export default () =>
  combineReducers({
    form,
    questions,
    responses,
    avatar,
    reports,
    reportById,
    reportProcess,
    goal,
    reportList,
    loginRedirect,
    profile,
    modal,
    chatWindow,
    chat,
    conversationalAssessment,
    chatResponses,
    chatReport,
    requestCoach,
    dashBoard,
    videoTherapy,
    inPersonTherapy,
    companyList,
    companyDetails,
    registrationFields,
    coachChat,
    sessionExpired,
    media,
    workLifeRequest,
    currentUser,
    infoTab,
    pushNotificationData,
    keyboard,
    videoMode,
    settings,
    carePlan,
    recentActivity,
    calendarEvents,
    appSync,
    chatState,
    idTransfer,
    isAnonymousAlertVisible,
    subtitles,
    reportReadMore,
    conversation,
    feedbackSurvey,
    skipToMainContent,
    locationRedirection
  });
